import { useState, useEffect } from "react";
import {
  FormWithRedirect,
  BooleanInput,
  ImageInput,
  Toolbar,
  SaveButton,
  required,
  maxLength,
} from "react-admin";
import { Typography, InputLabel, Container, Grid } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import SubjectIcon from "@material-ui/icons/Subject";
import CheckIcon from "@material-ui/icons/Check";
import ImageIcon from "@material-ui/icons/Image";
import axios from "axios";

import { colors } from "../../App";
import Block from "../../components/Block";
import {
  TextInput,
  RichTextInput,
  ImageInputPreview,
  AutocompleteInput,
} from "../../components/inputs";

function isPublicValidation(value, allValues) {
  const fields = {
    firstname: "Le membre doit avoir un prénom.",
    lastname: "Le membre doit avoir un nom.",
    biography: "Le membre doit avoir une biographie.",
  };
  if (value) {
    for (const key of Object.keys(fields)) {
      if (!allValues[key]) {
        return `Conditions de publication non remplies : ${fields[key]}`;
      }
    }
  }
}

function MemberForm(props) {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    axios
      .get("/members/roles")
      .then((res) =>
        setRoles(res.data.map((role) => ({ id: role, name: role })))
      );
  }, []);

  return (
    <FormWithRedirect
      render={(formProps) => (
        <form>
          <Container>
            <Grid container spacing={3}>
              <Grid item xl={9} lg={8}>
                <Block title="Identité" icon={PersonIcon}>
                  <TextInput
                    source="firstname"
                    label="Prénom"
                    validate={[required(), maxLength(80)]}
                  />
                  <TextInput
                    source="lastname"
                    label="Nom"
                    validate={[required(), maxLength(80)]}
                  />
                  <TextInput
                    source="title"
                    label="Titre"
                    validate={[maxLength(80)]}
                  />
                  <AutocompleteInput
                    source="role"
                    choices={roles}
                    label="Rôle"
                  />
                </Block>
                <Block title="Biographie" icon={SubjectIcon}>
                  <RichTextInput source="biography" />
                </Block>
              </Grid>
              <Grid item xl={3} lg={4}>
                <Block
                  title="Publication"
                  icon={CheckIcon}
                  bottom={
                    <Toolbar
                      style={{
                        borderTop: `1px solid ${colors.divider}`,
                        justifyContent: "center",
                      }}
                    >
                      <SaveButton
                        align="center"
                        label="Enregistrer"
                        saving={formProps.saving}
                        handleSubmitWithRedirect={
                          formProps.handleSubmitWithRedirect
                        }
                        disabled={formProps.invalid}
                      />
                    </Toolbar>
                  }
                >
                  <BooleanInput
                    source="is_public"
                    label="Rendre public"
                    validate={isPublicValidation}
                  />
                </Block>

                <Block title="Photo" icon={ImageIcon}>
                  {formProps.record.photo_url && (
                    <>
                      <InputLabel shrink>Photo actuelle</InputLabel>
                      <img
                        style={{ margin: "8px 0", width: "100%" }}
                        src={
                          process.env.REACT_APP_API_URL +
                          formProps.record.photo_url
                        }
                        alt=""
                      />
                    </>
                  )}
                  <ImageInput
                    source="photo_url"
                    label="Nouvelle photo"
                    accept="image/jpeg"
                    placeholder={
                      <Typography>
                        Déposez une image ou cliquez pour en sélectionner une.
                      </Typography>
                    }
                    maxSize={10000000}
                  >
                    <ImageInputPreview source="src" title="title" />
                  </ImageInput>
                </Block>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}
      {...props}
    />
  );
}

export default MemberForm;

import {
  Create,
  List,
  Edit,
  Datagrid,
  NumberField,
  TextField,
  DateField,
  BooleanField,
} from "react-admin";

import Form from "./NoteForm";

function NoteCreate(props) {
  return (
    <Create title="Ajouter une nouvelle note" component="div" {...props}>
      <Form />
    </Create>
  );
}

function NoteList(props) {
  return (
    <List
      sort={{ field: "created_at", order: "DESC" }}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="edit">
        <NumberField source="number" label="Numéro" />
        <TextField source="title" label="Titre" />
        <BooleanField source="is_public" label="Public" />
        <DateField source="published_at" label="Date de publication" />
        <DateField source="created_at" label="Date de création" />
        <DateField source="updated_at" label="Date de mise à jour" />
      </Datagrid>
    </List>
  );
}

function NoteEdit(props) {
  return (
    <Edit title="Modifier une note" component="div" {...props}>
      <Form />
    </Edit>
  );
}

const notes = {
  create: NoteCreate,
  list: NoteList,
  edit: NoteEdit,
};

export default notes;

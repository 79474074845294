import {
  Create,
  List,
  Edit,
  Datagrid,
  NumberField,
  TextField,
  DateField,
  TopToolbar,
  CreateButton,
} from "react-admin";
import { Route } from "react-router";
import { Drawer } from "@material-ui/core";

import Form from "./TopicForm";

function TopicCreate(props) {
  return (
    <>
      <Create title=" " component="div" {...props}>
        <Form onCancel={props.onCancel} />
      </Create>
    </>
  );
}

function TopicListActions({ basePath }) {
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
}

function TopicList(props) {
  const handleClose = () => props.history.push("/topics");

  return (
    <>
      <List
        actions={<TopicListActions />}
        sort={{ field: "created_at", order: "DESC" }}
        exporter={false}
        {...props}
      >
        <Datagrid rowClick="edit">
          <TextField source="title" label="Titre" />
          <NumberField source="notes_count" label="Notes associées" />
          <DateField source="created_at" label="Date de création" />
          <DateField source="updated_at" label="Date de mise à jour" />
        </Datagrid>
      </List>
      <Route path="/topics/create">
        {({ match }) => (
          <Drawer open={!!match} anchor="right" onClose={handleClose}>
            <TopicCreate onCancel={handleClose} {...props} />
          </Drawer>
        )}
      </Route>
      <Route path="/topics/:id">
        {({ match }) => {
          const isMatch = match && match.params && match.params.id !== "create";

          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose}>
              {isMatch ? (
                <TopicEdit
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                />
              ) : null}
            </Drawer>
          );
        }}
      </Route>
    </>
  );
}

function TopicEdit(props) {
  return (
    <Edit title=" " component="div" {...props}>
      <Form onCancel={props.onCancel} />
    </Edit>
  );
}

const topics = {
  list: TopicList,
};

export default topics;

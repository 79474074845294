import {
  Create,
  List,
  Edit,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  TopToolbar,
  CreateButton,
} from "react-admin";
import { Route } from "react-router";
import { Drawer } from "@material-ui/core";

import Form from "./TagForm";

function TagCreate(props) {
  return (
    <>
      <Create title=" " component="div" {...props}>
        <Form onCancel={props.onCancel} />
      </Create>
    </>
  );
}

function TagListActions({ basePath }) {
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
}

function TagList(props) {
  const handleClose = () => props.history.push("/tags");

  return (
    <>
      <List
        {...props}
        actions={<TagListActions />}
        sort={{ field: "created_at", order: "DESC" }}
        exporter={false}
      >
        <Datagrid rowClick="edit">
          <TextField source="title" label="Titre" />
          <NumberField source="notes_count" label="Notes associées" />
          <DateField source="created_at" label="Date de création" />
          <DateField source="updated_at" label="Date de mise à jour" />
        </Datagrid>
      </List>
      <Route path="/tags/create">
        {({ match }) => (
          <Drawer open={!!match} anchor="right" onClose={handleClose}>
            <TagCreate onCancel={handleClose} {...props} />
          </Drawer>
        )}
      </Route>
      <Route path="/tags/:id">
        {({ match }) => {
          const isMatch = match && match.params && match.params.id !== "create";

          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose}>
              {isMatch ? (
                <TagEdit
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                />
              ) : null}
            </Drawer>
          );
        }}
      </Route>
    </>
  );
}

function TagEdit(props) {
  return (
    <Edit title=" " component="div" {...props}>
      <Form onCancel={props.onCancel} />
    </Edit>
  );
}

const tags = {
  list: TagList,
};

export default tags;

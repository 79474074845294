import {
  Create,
  List,
  Edit,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
} from "react-admin";

import Form from "./MemberForm";

function MemberCreate(props) {
  return (
    <Create title="Ajouter un nouveau membre" component="div" {...props}>
      <Form />
    </Create>
  );
}

function MemberList(props) {
  return (
    <List sort={{ field: "lastname" }} exporter={false} {...props}>
      <Datagrid rowClick="edit">
        <TextField source="role" label="Rôle" />
        <TextField source="firstname" label="Prénom" />
        <TextField source="lastname" label="Nom" />
        <BooleanField source="is_public" label="Public" />
        <DateField source="created_at" label="Date de création" />
        <DateField source="updated_at" label="Date de mise à jour" />
      </Datagrid>
    </List>
  );
}

function MemberEdit(props) {
  return (
    <Edit title="Modifier un membre" component="div" {...props}>
      <Form />
    </Edit>
  );
}

const members = {
  create: MemberCreate,
  list: MemberList,
  edit: MemberEdit,
};

export default members;

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Divider,
} from "@material-ui/core";

import { colors } from "../App";

function Block({
  title,
  subheader,
  icon: Icon,
  children,
  bottom,
  innerProps = {},
}) {
  return (
    <Box mb={3}>
      <Card {...innerProps.card}>
        {title && (
          <>
            <CardHeader
              title={title}
              titleTypographyProps={subheader ? undefined : { variant: "h5" }}
              subheader={subheader}
              avatar={
                Icon && (
                  <Avatar style={{ backgroundColor: colors.primary.light }}>
                    <Icon />
                  </Avatar>
                )
              }
            />
            <Divider />
          </>
        )}
        <CardContent>{children}</CardContent>
        {bottom}
      </Card>
    </Box>
  );
}

export default Block;

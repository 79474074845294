import { Admin, Resource } from "react-admin";
import { createTheme } from "@material-ui/core/styles";
// import UserIcon from "@material-ui/icons/PersonTwoTone";
import NoteIcon from "@material-ui/icons/AssignmentTwoTone";
import TopicIcon from "@material-ui/icons/FolderOpenTwoTone";
import TagIcon from "@material-ui/icons/LocalOfferTwoTone";
import MemberIcon from "@material-ui/icons/PeopleTwoTone";

import { dataProvider, authProvider } from "./providers";
// import users from "./resources/users";
import notes from "./resources/notes/notes";
import topics from "./resources/topics/topics";
import tags from "./resources/tags/tags";
import members from "./resources/members/members";

function App() {
  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      disableTelemetry
      title="Administration"
      theme={theme}
    >
      {/* <Resource
        name="users"
        icon={UserIcon}
        {...users}
      /> */}
      <Resource name="notes" icon={NoteIcon} {...notes} />
      <Resource
        name="topics"
        options={{ label: "Dossiers" }}
        icon={TopicIcon}
        {...topics}
      />
      <Resource
        name="tags"
        options={{ label: "Thèmes" }}
        icon={TagIcon}
        {...tags}
      />
      <Resource
        name="members"
        options={{ label: "Membres" }}
        icon={MemberIcon}
        {...members}
      />
    </Admin>
  );
}

export default App;

export const theme = createTheme({
  palette: {
    secondary: { main: "#EE776E", dark: "#6e3842", contrastText: "#fff" },
    primary: {
      main: "#3E9EA3",
      light: "#a7d3d7",
      dark: "#409298",
    },
  },
  overrides: {
    RaFileInput: {
      dropZone: {
        border: "1px solid rgba(0,0,0,0.12)",
        borderRadius: "4px",
        color: "#3E9EA3 !important",
      },
    },
    RaFileField: {
      root: {
        "& a": {
          color: "#EE776E",
        },
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "#3E9EA3",
        borderRadius: "4px",
        color: "white",
        fontWeight: "500",
        letterSpacing: "0.05rem",
      },
    },
  },
});

export const colors = theme.palette;

import {
  useGetList,
  FormWithRedirect,
  BooleanInput,
  ImageInput,
  FileInput,
  Toolbar,
  SaveButton,
  minLength,
  maxLength,
} from "react-admin";
import {
  Box,
  Typography,
  InputLabel,
  Button,
  Container,
  Grid,
} from "@material-ui/core";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import SubjectIcon from "@material-ui/icons/Subject";
import ListIcon from "@material-ui/icons/List";
import CheckIcon from "@material-ui/icons/Check";
import ImageIcon from "@material-ui/icons/Image";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { pdfjs, Document, Page } from "react-pdf";

import { colors } from "../../App";
import Block from "../../components/Block";
import {
  TextInput,
  RichTextInput,
  NumberInput,
  DateTimeInput,
  FileInputPreview,
  ImageInputPreview,
  ReferenceInput,
  ReferenceArrayInput,
} from "../../components/inputs";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function isPublicValidation(value, allValues) {
  const fields = {
    number: "La note doit avoir un numéro.",
    title: "La note doit avoir un titre.",
    body: "Le corps de la note est manquant.",
    pdf_url: "La note doit comprendre un document PDF.",
  };
  if (value) {
    for (const key of Object.keys(fields)) {
      if (!allValues[key]) {
        return `Conditions de publication non remplies : ${fields[key]}`;
      }
    }
  }
}

function NoteForm(props) {
  const { total } = useGetList("notes");

  return (
    <FormWithRedirect
      render={(formProps) => (
        <form>
          <Container>
            <Grid container spacing={3}>
              <Grid item xl={9} lg={8}>
                <Block title="Entêtes" icon={TextFieldsIcon}>
                  <TextInput
                    source="title"
                    label="Titre"
                    validate={[minLength(4), maxLength(90)]}
                  />
                  <ReferenceInput
                    reference="topics"
                    source="topic"
                    label="Dossiers"
                    optionText="title"
                    enableCreation
                    validate={[minLength(4), maxLength(90)]}
                  />
                  <ReferenceArrayInput
                    reference="tags"
                    source="tags"
                    label="Thèmes"
                    optionText="title"
                    enableCreation
                    validate={[minLength(2), maxLength(30)]}
                  />
                </Block>

                <Block
                  title="Corps de la note"
                  subheader="Ce contenu correspond au texte présent sur la page de la note. Il est obligatoire pour la publication de la note."
                  icon={SubjectIcon}
                  innerProps={{ card: { style: { overflow: "initial" } } }}
                >
                  <RichTextInput source="body" validate={minLength(300)} />
                </Block>

                <Block
                  title="Contenus optionnels"
                  subheader="Si ces contenus sont absents, ils seront générés à partir d'une portion du corps de la note lors de la publication."
                  icon={ListIcon}
                >
                  <TextInput
                    source="description"
                    label="Description"
                    helperText="Visible dans la description des moteurs de recherche et des réseaux sociaux."
                    multiline
                    minRows={2}
                    validate={[minLength(70), maxLength(160)]}
                  />
                  <TextInput
                    source="extract"
                    label="Extrait"
                    helperText="Extrait apparaissant dans les listes du site."
                    multiline
                    minRows={3}
                    validate={[minLength(70), maxLength(300)]}
                  />
                </Block>
              </Grid>
              <Grid item xl={3} lg={4}>
                <Block
                  title="Publication"
                  icon={CheckIcon}
                  bottom={
                    <Toolbar
                      style={{
                        borderTop: `1px solid ${colors.divider}`,
                        justifyContent: "center",
                      }}
                    >
                      <SaveButton
                        align="center"
                        label="Enregistrer"
                        saving={formProps.saving}
                        handleSubmitWithRedirect={
                          formProps.handleSubmitWithRedirect
                        }
                        disabled={formProps.invalid}
                      />
                    </Toolbar>
                  }
                >
                  <NumberInput
                    source="number"
                    label="Numéro de la note"
                    initialValue={total ? total + 1 : undefined}
                  />
                  <DateTimeInput
                    source="published_at"
                    label="Date de publication"
                  />
                  <BooleanInput
                    source="is_public"
                    label="Rendre public"
                    validate={isPublicValidation}
                  />
                </Block>

                <Block title="Couverture" icon={ImageIcon}>
                  {formProps.record.cover_url && (
                    <>
                      <InputLabel shrink>
                        Image de couverture actuelle
                      </InputLabel>
                      <img
                        style={{ margin: "8px 0", width: "100%" }}
                        src={
                          process.env.REACT_APP_API_URL +
                          formProps.record.cover_url
                        }
                        alt=""
                      />
                    </>
                  )}
                  <ImageInput
                    source="cover_url"
                    label="Nouvelle image de couverture"
                    accept="image/jpeg"
                    placeholder={
                      <Typography>
                        Déposez une image ou cliquez pour en sélectionner une.
                      </Typography>
                    }
                    maxSize={10000000}
                  >
                    <ImageInputPreview source="src" title="title" />
                  </ImageInput>
                </Block>

                <Block title="Document PDF" icon={InsertDriveFileIcon}>
                  {formProps.record.pdf_url && (
                    <>
                      <InputLabel shrink>Document PDF actuel</InputLabel>
                      <Box style={{ margin: "16px 0", textAlign: "center" }}>
                        <Button
                          variant="contained"
                          size="small"
                          startIcon={<VisibilityIcon />}
                          href={
                            process.env.REACT_APP_API_URL +
                            formProps.record.pdf_url
                          }
                          target="_blank"
                        >
                          Voir le PDF
                        </Button>
                      </Box>
                      <Document
                        file={
                          process.env.REACT_APP_API_URL +
                          formProps.record.pdf_url
                        }
                      >
                        <Page pageNumber={1} width={258} />
                      </Document>
                    </>
                  )}
                  <FileInput
                    source="pdf_url"
                    label="Nouveau document PDF"
                    accept="application/pdf"
                    placeholder={
                      <Typography>
                        Déposez un PDF ou cliquez pour en sélectionner un.
                      </Typography>
                    }
                    maxSize={10000000}
                  >
                    <FileInputPreview source="src" title="title" />
                  </FileInput>
                </Block>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}
      {...props}
    />
  );
}

export default NoteForm;

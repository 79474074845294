import { useRef, useEffect, useState } from "react";
import {
  TextInput as RaTextInput,
  DateTimeInput as RaDateTimeInput,
  FileField as RaFileField,
  ImageField as RaImageField,
  NumberInput as RaNumberInput,
  ReferenceInput as RaReferenceInput,
  ReferenceArrayInput as RaReferenceArrayInput,
  AutocompleteInput as RAAutocompleteInput,
  AutocompleteArrayInput,
  useDataProvider,
} from "react-admin";
import RaRichTextInput from "ra-input-rich-text";
import { makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles({
  richText: {
    "& .ql-editor": { minHeight: "350px" },
  },
});

export const TextInput = (props) => (
  <RaTextInput margin="normal" variant="outlined" fullWidth {...props} />
);

export const RichTextInput = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.richText}>
      <RaRichTextInput label={false} {...props} />
    </Box>
  );
};

export const NumberInput = (props) => (
  <RaNumberInput margin="normal" variant="outlined" fullWidth {...props} />
);

export const DateTimeInput = (props) => (
  <RaDateTimeInput margin="normal" variant="outlined" fullWidth {...props} />
);

export const ImageInputPreview = (props) => {
  if (typeof props.record !== "string") {
    return <RaImageField {...props} />;
  }

  return false;
};

export const FileInputPreview = (props) => {
  const fileEl = useRef(null);

  useEffect(() => {
    if (fileEl.current) {
      if (typeof props.record === "string") {
        fileEl.current.parentNode.style.display = "none";
      } else {
        fileEl.current.parentNode.style.display = "block";
      }
    }
  }, [props.record]);

  return (
    <span ref={fileEl}>
      <RaFileField {...props} />
    </span>
  );
};

export const ReferenceInput = (props) => {
  return (
    <RaReferenceInput
      format={(item) => (item ? item.id : null)}
      parse={(id) => (id ? { id } : null)}
      perPage={100}
      {...props}
    >
      <AutocompleteReferenceInput {...props} />
    </RaReferenceInput>
  );
};

const AutocompleteReferenceInput = (props) => {
  const dataProvider = useDataProvider();
  const [inputValue, setInputValue] = useState();

  function validation() {
    if (!inputValue || inputValue.length === 0) {
      return false;
    }

    let valid = true;

    if (typeof props.validate === "function") {
      valid = props.validate(inputValue) === undefined;
    } else {
      for (const ruleFn of props.validate) {
        valid = ruleFn(inputValue) === undefined;
        if (!valid) {
          break;
        }
      }
    }

    return valid;
  }

  return (
    <AutocompleteInput
      onCreate={
        props.enableCreation &&
        validation() &&
        ((value) =>
          dataProvider
            .create(props.reference, {
              data: { [props.optionText]: value },
            })
            .then(({ data }) => data))
      }
      onInputValueChange={(val) => setInputValue(val)}
      {...props}
    />
  );
};

export const AutocompleteInput = (props) => {
  return (
    <RAAutocompleteInput
      emptyValue={null}
      resettable
      margin="normal"
      variant="outlined"
      fullWidth
      {...props}
    />
  );
};

export const ReferenceArrayInput = (props) => {
  return (
    <RaReferenceArrayInput
      format={(items) => items && items.map((item) => item.id)}
      parse={(ids) =>
        ids.map((id) => ({
          id,
        }))
      }
      perPage={100}
      {...props}
    >
      <AutocompleteReferenceArrayInput {...props} />
    </RaReferenceArrayInput>
  );
};

const AutocompleteReferenceArrayInput = (props) => {
  const dataProvider = useDataProvider();
  const [inputValue, setInputValue] = useState("");

  function validation() {
    if (inputValue.length === 0) {
      return false;
    }

    if (!props.validate) {
      return true;
    }

    let valid = true;

    if (typeof props.validate === "function") {
      valid = props.validate(inputValue) === undefined;
    } else {
      for (const ruleFn of props.validate) {
        valid = ruleFn(inputValue) === undefined;
        if (!valid) {
          break;
        }
      }
    }

    return valid;
  }

  return (
    <AutocompleteArrayInput
      margin="normal"
      variant="outlined"
      fullWidth
      onCreate={
        props.enableCreation &&
        validation() &&
        ((value) =>
          dataProvider
            .create(props.reference, {
              data: { [props.optionText]: value },
            })
            .then(({ data }) => data))
      }
      onInputValueChange={(val) => val && setInputValue(val)}
      {...props}
    />
  );
};

import {
  FormWithRedirect,
  Toolbar,
  SaveButton,
  minLength,
  maxLength,
} from "react-admin";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { TextInput } from "../../components/inputs";

function TagForm(props) {
  return (
    <Box sx={{ p: "16px" }}>
      <FormWithRedirect
        render={(formProps) => (
          <form>
            <Typography component="h2" variant="h4" align="center" gutterBottom>
              {formProps.record.id
                ? "Modifier un thème"
                : "Ajouter un nouveau thème"}
            </Typography>
            <TextInput
              source="title"
              label="Titre"
              validate={[minLength(2), maxLength(30)]}
            />
            <Toolbar
              style={{ justifyContent: "space-between", minWidth: "400px" }}
            >
              <Button onClick={props.onCancel}>Annuler</Button>
              <SaveButton
                label="Enregistrer"
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                disabled={formProps.invalid}
              />
            </Toolbar>
          </form>
        )}
        {...props}
      />
    </Box>
  );
}

export default TagForm;

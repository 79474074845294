import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL + "/1";

axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "access_token"
)}`;

axios.interceptors.response.use(
  (res) => res,
  function (error) {
    if (error.response) {
      error.message = error.response.data.detail;
    }
    return Promise.reject(error);
  }
);

async function handleUpload(resource, data) {
  if (
    resource === "notes" &&
    ((data.cover_url && data.cover_url.rawFile) ||
      (data.pdf_url && data.pdf_url.rawFile))
  ) {
    const form = new FormData();

    if (data.cover_url && data.cover_url.rawFile) {
      form.append("cover", data.cover_url.rawFile);
    }
    if (data.pdf_url && data.pdf_url.rawFile) {
      form.append("pdf", data.pdf_url.rawFile);
    }

    return axios
      .post("/notes/files" + (data.id ? "?id=" + data.id : ""), form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) =>
        Object.keys(res.data)
          .filter((key) => res.data[key] !== null)
          .reduce(
            (filteredData, key) => ({ ...filteredData, [key]: res.data[key] }),
            {}
          )
      )
      .then((res) => ({ ...data, ...res }));
  }

  if (resource === "members" && data.photo_url && data.photo_url.rawFile) {
    const form = new FormData();
    form.append("photo", data.photo_url.rawFile);

    return axios
      .post("/members/files" + (data.id ? "?id=" + data.id : ""), form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => ({ ...data, ...res.data }));
  }

  return data;
}

export const dataProvider = {
  // todo: filters
  getList: (resource, { pagination, sort, filter }) =>
    axios
      .get(`/${resource}/`, {
        params: {
          page: pagination.page,
          per_page: pagination.perPage,
          sort: (sort.order === "DESC" ? "-" : "") + sort.field,
        },
      })
      .then(({ data }) => ({
        data: data.items,
        total: data.pagination.total,
      })),
  getOne: (resource, { id }) => axios.get(`/${resource}/${id}`),
  getMany: (resource, { ids }) =>
    axios
      .get(`/${resource}/`, {
        params: {
          id: ids.map((i) => i.id),
        },
      })
      .then(({ data }) => ({ data: data.items })),
  update: (resource, { id, data }) =>
    handleUpload(resource, data).then((data) =>
      axios.put(`/${resource}/${id}`, data)
    ),

  updateMany: () => Promise.reject(),
  create: (resource, { data }) =>
    handleUpload(resource, data).then((data) =>
      axios.post(`/${resource}/`, data)
    ),
  delete: () => Promise.reject(),
  deleteMany: (resource, { ids }) =>
    axios.delete(`/${resource}/`, { params: { ids } }),
};

export const authProvider = {
  login: (params) => {
    const form = new FormData();
    form.append("username", params.username);
    form.append("password", params.password);

    return axios
      .post("/public/auth/login", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        localStorage.setItem("access_token", data.access_token);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.access_token}`;
      });
  },
  checkError: (error) => {
    const status = error.response.status;
    if (status === 401) {
      localStorage.removeItem("access_token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("access_token") ? Promise.resolve() : Promise.reject(),
  logout: () => {
    localStorage.removeItem("access_token");
    return Promise.resolve();
  },
  getIdentity: () =>
    axios
      .get("/users/me")
      .then(({ data }) => ({ id: data.id, fullName: data.username }))
      .catch((err) => {
        if (err.response.status === 404) {
          localStorage.removeItem("access_token");
        }
        return Promise.reject(err);
      }),
  // todo: authorization
  getPermissions: (params) => Promise.resolve(),
};
